.bio {
  margin: 30px;
  text-align: justify;
}

.contact-links {
  font-size:larger;
}

.textcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.contact-links:hover {
  text-shadow: -.25px -.25px 0 black, 
                .25px .25px black;
}

.top-space {
  margin-top: 25px;
}

.contact-form {
  margin-top: 50px;
}

.my-nav {
  list-style: none;
}
.my-name{
  float: right;
}
.face {
  margin: 20px;
  height: 200px;
  width: 200px;
  float: left;
  border-radius: 2%;
}

.email {
  height: 24px;
  width: 24px;
  margin-top: 8px;
  margin-left: 10px;
}

.very-high {
  height: 100%;
}

.footer {
  background-color: gray;
  height: 69.5px;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  color:white;
  line-height: 30px;
  position:relative;
  bottom: 0;
  width:100%;
}
.space-from-footer {
  margin-bottom: 100px;
}
.bottom-space {
  margin-bottom: 20px;
}
.middle-spacing {
  margin-top: 20px;
}
.wrapping {
  min-height:100vh;
  position:relative;
}
.prof-icons {
  float: right;
  margin-left: 20px;
  width: 48px;
  height: 48px;
}

.zoom {
  transition: transform .2s; /* Animation */
  margin: 0 auto;
  margin-top: 10px;
}

.zoom:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  z-index: 99999 ;
}

.img {
  height: 365px;
}

.tech-title {
  font-weight: 600;
}

